import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import { BsArrowRightShort } from "react-icons/bs";
import { MdOutlineClear, MdVideoCameraFront } from "react-icons/md";
import ReactPlayer from "react-player";
import BlogCard from "../components/blogCard";
import ContactForm from "../components/contactForm";
import PriceCard from "../components/price-card";
import ReviewSection from "../components/review-section";
import BackgroundPattern2 from "../images/Behind photo logo.svg";
import BackgroundPattern from "../images/Hex bg.svg";
import BackgroundIcon from "../images/Homepage hero bg logo.svg";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				title
				pageFlexibleContent {
					pageFlexibleContent {
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_WhyChoose {
							fieldGroupName
							heading
							items {
								body
								heading
								icon {
									altText
									sourceUrl
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_OurExperts {
							body
							fieldGroupName
							heading
							items {
								body
								heading
								icon {
									altText
									sourceUrl
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_ImageSide {
							body
							fieldGroupName
							heading
							button {
								title
								url
							}
							image {
								altText
								sourceUrl
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_HomepageBanner {
							fieldGroupName
							homepageBannerHeading
							homepageBannerSubHeading
							homepageBannerDescription
							homepageBannerLink {
								target
								title
								url
							}
							homepageBannerImage {
								link
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_BasicContentSection {
							basicContent
							fieldGroupName
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_AllServices {
							fieldGroupName
							services {
								readMoreLink
								serviceHeading
								serviceSubHeading
								serviceIcon {
									sourceUrl
									altText
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_ItServices {
							fieldGroupName
							itServicesHeading
							itServicesDescription
							itServicesNotice
							itServices {
								itService
								itServiceContent
								itServiceLink {
									target
									title
									url
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_HelpBusiness {
							fieldGroupName
							helpBusinessHeading
							helpBusinessContent
							helpBusinessImage {
								localFile {
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: AUTO
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									publicURL
								}
								altText
							}
							helpBusinessVideoButton
							helpBusinessVideo {
								publicUrl
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_TechnologyPartners {
							fieldGroupName
							technologyPartnersHeading
							technologyPartners {
								technologyPartnerUrl
								technologyPartnerLogo {
									sourceUrl
									altText
								}
							}
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_OurLatestBlog {
							fieldGroupName
							ourLatestBlogHeading
							ourLatestBlogDescription
							numberOfLatestBlog
						}
						... on WpPage_Pageflexiblecontent_PageFlexibleContent_GetInTouch {
							fieldGroupName
							getInTouchHeading
						}
					}
				}
			}
			allWpPost(
				sort: { fields: dateGmt, order: DESC }
				filter: { title: { ne: "Website Images" } }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt(formatString: "D MMMM yyyy")
					blogFields {
						featuredImage {
							localFile {
								childImageSharp {
									gatsbyImageData(formats: AUTO)
								}
							}
							altText
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const {
		pageData: {
			pageFlexibleContent: { pageFlexibleContent },
		},
	} = data;

	const blogSection = () => {
		let heading = "Our latest blog posts",
			numberOfPOst = 12,
			desc =
				"Stay updated with our cutting-edge tech insights and troubleshooting tips through our latest blog articles, designed to empower you with comprehensive IT support knowledge.";
		if (pageFlexibleContent.length > 0) {
			const reviewSection = pageFlexibleContent.filter(
				(item) =>
					item.fieldGroupName ==
					"Page_Pageflexiblecontent_PageFlexibleContent_OurLatestBlog"
			);
			if (reviewSection.length > 0) {
				heading = reviewSection[0].ourLatestBlogHeading;
				desc = reviewSection[0].ourLatestBlogDescription;
				numberOfPOst = reviewSection[0].numberOfLatestBlog;
			}
		}

		let allPost = data.allWpPost.nodes;
		allPost = allPost.slice(0, numberOfPOst);

		return (
			<section className="py-5 py-lg-7">
				<Container>
					<Row>
						<Col lg={8}>
							<h2 className="fs-1">{heading}</h2>
							<p className="pb-xl-5 pb-4">{desc}</p>
						</Col>
					</Row>
					<Row className="g-5 g-xl-6">
						{allPost.map((blog, index) => (
							<BlogCard
								img={
									blog.blogFields.featuredImage.localFile.childImageSharp
										.gatsbyImageData
								}
								imgAlt={blog.blogFields.featuredImage.altText}
								title={blog.title}
								date={blog.dateGmt}
								url={`/blog/${blog.slug}`}
							/>
						))}
					</Row>
				</Container>
			</section>
		);
	};

	const getInTouch = () => {
		let heading = "Get in touch to find out how we can work together";
		if (pageFlexibleContent.length > 0) {
			const reviewSection = pageFlexibleContent.filter(
				(item) =>
					item.fieldGroupName ==
					"Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
			);
			if (reviewSection.length > 0) {
				heading = reviewSection[0].getInTouchHeading;
			}
		}
		return (
			<section style={{ zIndex: 3 }} className="pb-5 py-lg-7 position-relative">
				<Container>
					<Row>
						<Col>
							<h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={8}>
							<ContactForm />
						</Col>
					</Row>
				</Container>
			</section>
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Home | Cranfield IT Solutions"
				description="Managed IT services with transparent pricing."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | Cranfield IT Solutions",
					description: "Managed IT services with transparent pricing.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{/* <BgImage
						Tag="section"
						className="hero"
						id="hero-home"
						image={pluginImage}
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col xs={12}>
									<h1
										className="text-uppercase text-white"
										id="home-hero-headline"
									>
										Lorem Ipsum
									</h1>
									<p className="text-white" id="home-hero-subheading">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nunc mollis nulla justo, quis venenatis enim tempor vitae.
										In hac habitasse platea dictumst. Nam lectus risus,
										convallis vel dictum eu, interdum a mi.
									</p>
								</Col>
							</Row>
						</Container>
					</BgImage> */}
				{pageFlexibleContent.length > 0 &&
					pageFlexibleContent.map((pageInfo, i) => (
						<>
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_WhyChoose" && (
								<section className="py-5 mt-lg-5">
									<Container>
										<Row>
											<Col className="text-center">
												<h2 className="fs-1 mb-5 mb-lg-6 text-center">
													{pageInfo.heading}
												</h2>
											</Col>
										</Row>
										{pageInfo.items.length > 0 && (
											<Row className="g-5 g-xl-6 justify-content-center">
												{pageInfo.items.map((serviceInfo, i) => (
													<Col
														className="text-center"
														style={{ minHeight: "100%" }}
														sm={6}
														md={4}
													>
														<div>
															<img
																alt={serviceInfo.icon?.altText}
																src={serviceInfo.icon?.sourceUrl}
															/>
															<h3 className="pt-3 pb-2">
																{serviceInfo.heading}
															</h3>
															<p>{serviceInfo.body}</p>
														</div>
													</Col>
												))}
											</Row>
										)}
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_ImageSide" && (
								<section className="py-5 mt-lg-5">
									<Container>
										<Row className="g-5 g-xl-6 align-items-center">
											<Col lg={4}>
												<img
													className="w-100"
													alt={pageInfo.image?.altText}
													src={pageInfo.image?.sourceUrl}
												/>
											</Col>
											<Col lg={8} className="">
												<h2 className="fs-1  ">{pageInfo.heading}</h2>
												<p className="py-2">{pageInfo.body}</p>
												<Link
													className="btn btn-primary px-5  mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
													to={pageInfo.button.url}
													rel="noopener noreferrer"
												>
													{pageInfo.button.title}
												</Link>
											</Col>
										</Row>
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_OurExperts" && (
								<section className="py-5 mt-lg-5">
									<Container>
										<Row>
											<Col className="text-center">
												<h2 className="fs-1 mb-4 text-center">
													{pageInfo.heading}
												</h2>
												<p className="mb-5 mb-lg-6">{pageInfo.body}</p>
											</Col>
										</Row>
										{pageInfo.items.length > 0 && (
											<Row className="g-5 g-lg-6 justify-content-center">
												{pageInfo.items.map((serviceInfo, i) => (
													<Col
														className="text-center"
														style={{ minHeight: "100%" }}
														sm={6}
														md={4}
													>
														<div>
															<img
																alt={serviceInfo.icon?.altText}
																src={serviceInfo.icon?.sourceUrl}
															/>
															<h3 className="pt-3 pb-2">
																{serviceInfo.heading}
															</h3>
															<p>{serviceInfo.body}</p>
														</div>
													</Col>
												))}
											</Row>
										)}
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_AllServices" && (
								<section className="pb-5 mt-5">
									<Container>
										{pageInfo.services.length > 0 && (
											<Row className="g-3">
												{pageInfo.services.map((serviceInfo, i) => (
													<Col style={{ minHeight: "100%" }} sm={6}>
														<div
															style={{
																zIndex: 2,
																borderRadius: "4px",
																border: "1px solid #4FC4D8",
															}}
															className="bg-black h-100 py-3 px-4 mb-3 d-flex justify-content-center align-items-center"
														>
															<div className="flex-grow-1">
																<h3>
																	<span style={{ color: "#4FC4D8" }}>
																		{serviceInfo.serviceSubHeading}
																	</span>{" "}
																</h3>
																<p className="fs-5">
																	{serviceInfo.serviceHeading}
																</p>
																{serviceInfo.readMoreLink && (
																	<div
																		style={{ zIndex: 3 }}
																		className="position-relative"
																	>
																		<Link
																			to={serviceInfo.readMoreLink}
																			className="white-link"
																		>
																			Read More
																		</Link>
																	</div>
																)}
															</div>
															<img
																alt={serviceInfo.serviceIcon?.altText}
																src={serviceInfo.serviceIcon.sourceUrl}
															/>
														</div>
													</Col>
												))}
											</Row>
										)}
									</Container>
								</section>
							)}

							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_BasicContentSection" && (
								<Container className="">
									{" "}
									<Row>
										<Col
											dangerouslySetInnerHTML={{
												__html: pageInfo.basicContent,
											}}
										/>
									</Row>
								</Container>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_HomepageBanner" && (
								<section
									className="py-md-7  py-5 py-lg-10 position-relative"
									key={i}
								>
									<div
										style={{ right: "7rem" }}
										className="position-absolute d-none d-lg-block bottom-0"
									>
										<img
											alt=""
											className=""
											style={{ maxWidth: "800px" }}
											src={BackgroundIcon}
										/>
									</div>
									<div
										style={{ right: "2rem", top: "-4rem" }}
										className="position-absolute d-md-none "
									>
										<img
											alt=""
											className=""
											style={{ maxWidth: "200px" }}
											src={pageInfo.homepageBannerImage.link}
										/>
									</div>
									<div
										style={{ right: "4rem", top: "0rem" }}
										className="position-absolute d-none d-md-block d-lg-none "
									>
										<img
											alt=""
											className=""
											style={{ maxWidth: "300px" }}
											src={pageInfo.homepageBannerImage.link}
										/>
									</div>

									<Container
										style={{ zIndex: 2 }}
										className="position-relative"
									>
										<Row>
											<Col lg={6}>
												<h2 className="text-light-grey d-none text-uppercase d-lg-block fs-3">
													{pageInfo.homepageBannerSubHeading}
												</h2>
												<h2 className="text-light-grey d-lg-none fs-5">
													{pageInfo.homepageBannerSubHeading}
												</h2>
												<h1
													className="text-white display-5"
													dangerouslySetInnerHTML={{
														__html: pageInfo.homepageBannerHeading,
													}}
												/>
												<div
													dangerouslySetInnerHTML={{
														__html: pageInfo.homepageBannerDescription,
													}}
												/>
												{/* <Button
                            as={Link}
                            to="/contact-us"
                            variant="primary"
                            className="px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
                            >
                            Book a discovery call
                          </Button> */}

												<a
													className="btn btn-primary px-5  mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
													href={pageInfo.homepageBannerLink.url}
													target={pageInfo.homepageBannerLink.target}
													rel="noopener noreferrer"
												>
													{pageInfo.homepageBannerLink.title}
												</a>

												{/* <Button
                            style={{ paddingLeft: "18px", paddingRight: "18px" }}
                            onClick={handleShow}
                            variant="outline-primary"
                            className=" mt-xl-5 mt-md-4 mt-xl-0 py-3 w-100 w-md-auto"
                            >
                            <FaPlay className="fs-5" />
                          </Button> */}
											</Col>
										</Row>
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_ItServices" && (
								<section className=" pb-5 py-lg-7 position-relative" key={i}>
									<div
										style={{ zIndex: 1 }}
										className="position-absolute d-none d-lg-block start-50 translate-middle-x top-50 bottom-0"
									>
										<img alt="" className="" src={BackgroundPattern} />
									</div>
									<Container
										style={{ zIndex: 2 }}
										className="position-relative"
									>
										<Row className="pb-lg-5 pb-4">
											<Col>
												<h2 className="fs-1 mb-4">
													{pageInfo.itServicesHeading}
												</h2>
												<div
													dangerouslySetInnerHTML={{
														__html: pageInfo.itServicesDescription,
													}}
												/>
											</Col>
										</Row>
										<Row className="h-100 g-5 g-lg-2 g-xl-5">
											{pageInfo.itServices.length > 0 &&
												pageInfo.itServices.map((serviceItem, j) => (
													<PriceCard
														key={j}
														title={
															<>
																<span
																	dangerouslySetInnerHTML={{
																		__html: serviceItem.itService,
																	}}
																/>
															</>
														}
														list={
															<div
																dangerouslySetInnerHTML={{
																	__html: serviceItem.itServiceContent,
																}}
															/>
														}
														buttonHeight="4.5rem"
														buttonLink={serviceItem.itServiceLink.url}
														buttonTarget={serviceItem.itServiceLink.target}
														buttonTitle={serviceItem.itServiceLink.title}
													/>
												))}
										</Row>
										<Row>
											<Col className="text-center mt-5">
												<p>{pageInfo.itServicesNotice}</p>
											</Col>
										</Row>
										{/* <Row>
                        <Col className="text-center pt-4">
                          <Link to="/it-support-services-startups">
                            <Button
                              variant="outline-primary"
                              className="px-5  d-none  d-lg-inline-block  py-3 w-100 w-md-auto"
                            >
                              <div className=" d-flex text-white align-items-center justify-content-center">
                                IT Support for Startups
                              </div>
                            </Button>
                          </Link>
                        </Col>
                      </Row> */}
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_HelpBusiness" && (
								<section className=" position-relative ">
									<Container className=" position-relative py-5 pb-lg-7">
										<div
											style={{ zIndex: 1 }}
											className="bg-black position-absolute d-none d-lg-block ps-5 ps-xl-8 text-end top-0 end-0"
										>
											<img alt="" className="" src={BackgroundPattern2} />
										</div>
										<div
											style={{ zIndex: 1, bottom: "8rem" }}
											className="bg-black position-absolute d-md-none w-60 ps-5 text-end end-0"
										>
											<img
												alt=""
												style={{ maxHeight: "10rem" }}
												src={BackgroundPattern2}
											/>
										</div>
										<div
											style={{ zIndex: 1, bottom: "8rem" }}
											className="bg-black position-absolute d-none d-md-block d-lg-none w-60 ps-5 text-end end-0"
										>
											<img
												style={{ maxHeight: "20rem" }}
												src={BackgroundPattern2}
											/>
										</div>
										<Row
											style={{ zIndex: 3 }}
											className="gx-0 position-relative"
										>
											<Col lg={{ span: 6 }}>
												<h2 className="pb-5 fs-1">
													{pageInfo.helpBusinessHeading}
												</h2>
												<div
													dangerouslySetInnerHTML={{
														__html: pageInfo.helpBusinessContent,
													}}
													className="helpbusinee__items"
												/>

												{/* <div className="d-flex pb-4 align-items-start">
                            <img
                              style={{ maxWidth: "24px" }}
                              className="me-3"
                              alt="Calendar icon"
                              src={Calendar}
                            />
                            <p className="pb-0 mb-0">
                              We offer rolling monthly contracts.
                            </p>
                          </div>
                          <div className="d-flex pb-4 align-items-start">
                            <img
                              alt="Umbrella icon"
                              style={{ maxWidth: "24px" }}
                              className="me-3"
                              src={Umbrella}
                            />
                            <p className="pb-0 mb-0">
                              A single bill covers all your necessary services.
                            </p>
                          </div>
                          <div className="d-flex pb-4 align-items-start">
                            <img
                              style={{ maxWidth: "24px" }}
                              alt="Hands icon"
                              className="me-3"
                              src={Hands}
                            />
                            <p className="pb-0 mb-0">
                              We are small enough to care, but large enough to
                              <br /> manage your technology.
                            </p>
                          </div>

                          <Button
                            onClick={handleShow}
                            variant="outline-primary"
                            className="px-5  d-none  d-lg-inline-block  py-3 w-100 w-md-auto"
                          >
                            <div className=" d-flex text-white align-items-center justify-content-center">
                              Find out more
                              <MdVideoCameraFront className="ms-2 fs-2" />
                            </div>
                          </Button> */}
											</Col>
											<Col lg={6}>
												<div
													className="position-relative d-none d-xl-block  start--20 mt-7 "
													style={{
														overflow: "hidden",

														borderRadius: "8px",
													}}
												>
													<GatsbyImage
														className="w-100"
														style={{ maxHeight: "25rem" }}
														image={
															pageInfo.helpBusinessImage?.localFile
																.childImageSharp.gatsbyImageData
														}
														alt={pageInfo.helpBusinessImage?.altText}
													/>
												</div>
												<div
													className="position-relative d-none d-lg-block d-xl-none  start-0 mt-7 "
													style={{
														overflow: "hidden",

														borderRadius: "8px",
													}}
												>
													<GatsbyImage
														className="w-100"
														style={{ maxHeight: "25rem" }}
														image={
															pageInfo.helpBusinessImage?.localFile
																.childImageSharp.gatsbyImageData
														}
														alt={pageInfo.helpBusinessImage?.altText}
													/>
												</div>

												<div
													className="position-relative  d-lg-none w-80   mt-5 "
													style={{
														overflow: "hidden",

														borderRadius: "8px",
													}}
												>
													<GatsbyImage
														className=""
														image={
															pageInfo.helpBusinessImage?.localFile
																.childImageSharp.gatsbyImageData
														}
														alt={pageInfo.helpBusinessImage?.altText}
													/>
												</div>
											</Col>
										</Row>
									</Container>
									<Container className="d-lg-none">
										<Row>
											<Col className="text-center">
												<Button
													onClick={handleShow}
													variant="outline-primary"
													className="px-5 py-3 w-100 w-md-auto"
												>
													<div className=" d-flex text-white align-items-center justify-content-center">
														{pageInfo.helpBusinessVideoButton ??
															"Find Out More"}
														<MdVideoCameraFront className="ms-2 fs-2" />
													</div>
												</Button>
											</Col>
										</Row>
										<Modal
											style={{ zIndex: 99999 }}
											size="lg"
											show={show}
											onHide={handleClose}
											centered
										>
											<Modal.Body className="bg-black" closeButton>
												<div className="text-center position-relative">
													<div className="position-absolute text-white end-0 top-0 p-lg-3">
														<MdOutlineClear
															style={{ cursor: "pointer" }}
															className="fs-4 "
															onClick={handleClose}
														/>
													</div>
													<div className="pb-5 pt-5">
														<ReactPlayer
															playing
															controls
															className="w-100 h-auto"
															url={
																pageInfo.helpBusinessVideo.publicUrl ??
																"https://cranfield-it-solutions.rjmdigital.net/wp-content/uploads/2023/06/Matt-Cranfield-Elevator-Pitch-to-go-on-home-page-play-button.mp4"
															}
														/>
													</div>
												</div>
											</Modal.Body>
										</Modal>
									</Container>
								</section>
							)}
							{pageInfo.fieldGroupName ==
								"Page_Pageflexiblecontent_PageFlexibleContent_TechnologyPartners" && (
								<section
									style={{ zIndex: 3 }}
									className="py-5 py-lg-7 position-relative"
								>
									<Container>
										<Row>
											<Col>
												<h2 className="fs-1 mb-5 mb-lg-6 text-center">
													{pageInfo.technologyPartnersHeading}
												</h2>
											</Col>
										</Row>
										{pageInfo.technologyPartners.length > 0 && (
											<Row className="align-items-center g-5">
												{pageInfo.technologyPartners.map((techItem, i) => (
													<Col
														className="text-center"
														xs={4}
														lg={i % 2 == 0 ? 2 : true}
														key={i}
													>
														<a
															target="_blank"
															rel="noreferrer"
															href={techItem.technologyPartnerUrl}
														>
															<img
																src={techItem?.technologyPartnerLogo.sourceUrl}
																alt={techItem?.technologyPartnerLogo?.altText}
																className="mw-100"
															/>
														</a>
													</Col>
												))}
											</Row>
										)}
									</Container>
								</section>
							)}
						</>
					))}
				<section className="pt-5 pt-lg-7">
					<ReviewSection />
				</section>
				{blogSection()}
				{getInTouch()}

				{/* <section id='content' className='pt-5 pt-lg-7'>
          <Container>
            <Row className='g-5 g-lg-6'>
              <Col className='d-none d-lg-block' lg={6}>
                <Sticky top={250} bottomBoundary='#content'>
                  <div></div> <h2 className='fs-1 mt-5'>Other IT services</h2>
                  <p>
                    In addition to our contracts, we provide a range of
                    standalone services, such as managed antivirus solutions,
                    password managers, data backup, and more.
                  </p>
                <Button
                    as={Link}
                    to="/contact-us"
                    variant="primary"
                    className="px-5  me-md-3 py-3 w-100 w-md-auto"
                  >
                    Book a sales call
                  </Button> 
                  <a
                    className='btn btn-primary px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto'
                    href='https://outlook.office365.com/owa/calendar/CranfieldITSolutions1@cranfielditsolutions.co.uk/bookings/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Book a sales call
                  </a>
                </Sticky>
              </Col>
              <Col className='d-lg-none' lg={6}>
                <div></div> <h2 className='fs-1 mt-5'>Other IT services</h2>
                <p>
                  In addition to our contracts, we provide a range of standalone
                  services, such as managed antivirus solutions, password
                  managers, data backup, and more.
                </p>
              <Button
                  as={Link}
                  to="/contact-us"
                  variant="primary"
                  className="px-5  me-md-3 py-3 w-100 w-md-auto"
                >
                  Book a sales call
                </Button> 
                <a
                  className='btn btn-primary px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto'
                  href='https://outlook.office365.com/owa/calendar/CranfieldITSolutions1@cranfielditsolutions.co.uk/bookings/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Book a sales call
                </a>
              </Col>
              <Col lg={6}>
                <div
                  style={{
                    zIndex: 2,
                    borderRadius: '4px',
                    border: '1px solid #4FC4D8',
                  }}
                  className='bg-black py-3 px-4 mb-3 position-relative'
                >
                  <div
                    style={{ zIndex: 3 }}
                    className='position-absolute pb-3 end-0 bottom-0'
                  >
                    <img alt='Advice icon' src={Advice} />
                  </div>
                  <div style={{ zIndex: 4 }} className='position-relative'>
                    <h3 className='pb-5 '>
                      Technology advice
                      <br className='d-md-none' /> & sales
                    </h3>
                    <div className='d-flex align-items-center'>
                      <Link to='/sales' className='blue-link roboto-medium'>
                        Read more
                        <BsArrowRightShort className='fs-2' />
                      </Link>
                    </div>
                  </div>
                </div>
                 something 
                <div
                  style={{
                    zIndex: 2,
                    borderRadius: '4px',
                    border: '1px solid #4FC4D8',
                  }}
                  className='bg-black py-3 px-4 mb-3 position-relative'
                >
                  <div
                    style={{ zIndex: 3 }}
                    className='position-absolute pb-3 end-0 bottom-0'
                  >
                    <img alt='Backups icon' src={Backups} />
                  </div>
                  <div style={{ zIndex: 4 }} className='position-relative'>
                    <h3 className='pb-5 '>
                      Automatic data
                      <br className='d-md-none' /> backups
                    </h3>
                    <div className='d-flex align-items-center'>
                      <Link
                        to='/data-backup'
                        className='blue-link roboto-medium'
                      >
                        Read more
                        <BsArrowRightShort className='fs-2' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    zIndex: 2,
                    borderRadius: '4px',
                    border: '1px solid #4FC4D8',
                  }}
                  className='bg-black py-3 px-4 mb-3 position-relative'
                >
                  <div
                    style={{ zIndex: 3 }}
                    className='position-absolute pb-3 end-0 bottom-0'
                  >
                    <img alt='Antivirus icon' src={Antivirus} />
                  </div>
                  <div style={{ zIndex: 4 }} className='position-relative'>
                    <h3 className='pb-5 '>
                      Business
                      <br className='d-md-none' /> antivirus
                    </h3>
                    <div className='d-flex align-items-center'>
                      <Link
                        to='/cyber-security'
                        className='blue-link roboto-medium'
                      >
                        Read more
                        <BsArrowRightShort className='fs-2' />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
			</Layout>
		</>
	);
};

export default IndexPage;
